import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ImpactsCreate, Impacts } from '../impacts';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type ImpactsProps = {
  onSubmit: (
    value: ImpactsCreate,
    helpers: FormikHelpers<ImpactsCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  impacts: Impacts | null;
};

const CreateImpactsForm = ({ onSubmit, submitting, impacts }: ImpactsProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ImpactsCreate,
    helpers: FormikHelpers<ImpactsCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/impacts', { replace: true });
    }
  };

  const initialValues: ImpactsCreate = !impacts
    ? {
        beneficiaries: '',
        projects: '',
        zone: '',
        partners: '',
      }
    : {
        beneficiaries: impacts.beneficiaries,
        projects: impacts.projects,
        zone: impacts.zone,
        partners: impacts.partners,
      };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="beneficiaries"
                      size="small"
                      variant="outlined"
                      label="Beneficiaries"
                      placeholder=""
                      value={formik.values.beneficiaries}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.beneficiaries)}
                      helperText={formik.errors.beneficiaries}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="projects"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Number of projects"
                      placeholder=""
                      value={formik.values.projects}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.projects)}
                      helperText={formik.errors.projects}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="zone"
                      size="small"
                      variant="outlined"
                      label="Zone/Woreda/Region"
                      placeholder=""
                      value={formik.values.zone}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.zone)}
                      helperText={formik.errors.zone}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="partners"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Partners"
                      placeholder=""
                      value={formik.values.partners}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.partners)}
                      helperText={formik.errors.partners}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateImpactsForm;
