import { Grid, Typography } from '@mui/material';
import { Media } from '../../utils/types';
import DeleteAction from './DeleteAction';

type FileListItemProps = {
  media: Media;
  onDelete: () => Promise<void>;
};

const FileListItem = ({ onDelete, media }: FileListItemProps) => {
  return (
    <>
      <Grid
        container
        alignItems="center"
        display="flex"
        flexWrap="nowrap"
        sx={{ mb: 1, borderBottom: '1px solid gray', py: 1, px: '4px' }}
      >
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography>{media.name}</Typography>
        </Grid>
        <Grid item>
          <DeleteAction
            message="Are you sure you want to delete file"
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FileListItem;
