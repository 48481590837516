import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListContactUsPage from '../../features/contactUs/pages/ListContactUsPage';
import ListNewsPage from '../../features/news/pages/ListNewsPage';
import CreateNewsPage from '../../features/news/pages/CreateNewsPage';
import EditNewsPage from '../../features/news/pages/EditNewsPage';
import ListStrategicPlanPage from '../../features/strategicPlan/pages/ListStrategicPlanPage';
import CreateStrategicPlanPage from '../../features/strategicPlan/pages/CreateStrategicPlanPage';
import EditStrategicPlanPage from '../../features/strategicPlan/pages/EditStrategicPlanPage';
import ListVideoPage from '../../features/video/pages/ListVideoPage';
import CreateVideoPage from '../../features/video/pages/CreateVideoPage';
import EditVideoPage from '../../features/video/pages/EditVideoPage';
import ListPublicationPage from '../../features/publication/pages/ListPublicationPage';
import CreatePublicationPage from '../../features/publication/pages/CreatePublicationPage';
import EditPublicationPage from '../../features/publication/pages/EditPublicationPage';
import ListGalleryPage from '../../features/gallery/pages/ListGalleryPage';
import CreateGalleryPage from '../../features/gallery/pages/CreateGalleryPage';
import EditGalleryPage from '../../features/gallery/pages/EditGalleryPage';
import ListAnnouncementPage from '../../features/announcement/pages/ListAnnouncementPage';
import CreateAnnouncementPage from '../../features/announcement/pages/CreateAnnouncementPage';
import EditAnnouncementPage from '../../features/announcement/pages/EditAnnouncementPage';
import CreateImpactsPage from '../../features/impacts/pages/CreateImpactsPage';
import ListProjectsPage from '../../features/projects/pages/ListProjectsPage';
import CreateProjectsPage from '../../features/projects/pages/CreateProjectsPage';
import EditProjectsPage from '../../features/projects/pages/EditProjectsPage';
import ListMemberPage from '../../features/member/pages/ListMemberPage';
import CreateMemberPage from '../../features/member/pages/CreateMemberPage';
import EditMemberPage from '../../features/member/pages/EditMemberPage';
import ListTeamPage from '../../features/team/pages/ListTeamPage';
import CreateTeamPage from '../../features/team/pages/CreateTeamPage';
import EditTeamPage from '../../features/team/pages/EditTeamPage';
import ListLinkPage from '../../features/link/pages/ListLinkPage';
import CreateLinkPage from '../../features/link/pages/CreateLinkPage';
import EditLinkPage from '../../features/link/pages/EditLinkPage';
import ListOtherPublicationPage from '../../features/otherPublication/pages/ListOtherPublicationPage';
import CreateOtherPublicationPage from '../../features/otherPublication/pages/CreateOtherPublicationPage';
import EditOtherPublicationPage from '../../features/otherPublication/pages/EditOtherPublicationPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <ListContactUsPage />
            </PrivateRoute>
          }
        />

        {/* News  */}
        <Route
          path="/news"
          element={
            <PrivateRoute>
              <ListNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/create"
          element={
            <PrivateRoute>
              <CreateNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/news/edit/:id"
          element={
            <PrivateRoute>
              <EditNewsPage />
            </PrivateRoute>
          }
        />

        {/* Strategic Plan  */}
        <Route
          path="/strategic-plans"
          element={
            <PrivateRoute>
              <ListStrategicPlanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/strategic-plans/create"
          element={
            <PrivateRoute>
              <CreateStrategicPlanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/strategic-plans/edit/:id"
          element={
            <PrivateRoute>
              <EditStrategicPlanPage />
            </PrivateRoute>
          }
        />

        {/* Video  */}
        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <ListVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/create"
          element={
            <PrivateRoute>
              <CreateVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/edit/:id"
          element={
            <PrivateRoute>
              <EditVideoPage />
            </PrivateRoute>
          }
        />

        {/* Publication  */}
        <Route
          path="/publications"
          element={
            <PrivateRoute>
              <ListPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/create"
          element={
            <PrivateRoute>
              <CreatePublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publications/edit/:id"
          element={
            <PrivateRoute>
              <EditPublicationPage />
            </PrivateRoute>
          }
        />

        {/* Gallery  */}
        <Route
          path="/galleries"
          element={
            <PrivateRoute>
              <ListGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/create"
          element={
            <PrivateRoute>
              <CreateGalleryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/galleries/edit/:id"
          element={
            <PrivateRoute>
              <EditGalleryPage />
            </PrivateRoute>
          }
        />

        {/* Announcement  */}
        <Route
          path="/announcements"
          element={
            <PrivateRoute>
              <ListAnnouncementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/announcements/create"
          element={
            <PrivateRoute>
              <CreateAnnouncementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/announcements/edit/:id"
          element={
            <PrivateRoute>
              <EditAnnouncementPage />
            </PrivateRoute>
          }
        />

        {/* Project  */}
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <ListProjectsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/create"
          element={
            <PrivateRoute>
              <CreateProjectsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/edit/:id"
          element={
            <PrivateRoute>
              <EditProjectsPage />
            </PrivateRoute>
          }
        />

        {/* Member  */}
        <Route
          path="/members"
          element={
            <PrivateRoute>
              <ListMemberPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/members/create"
          element={
            <PrivateRoute>
              <CreateMemberPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/members/edit/:id"
          element={
            <PrivateRoute>
              <EditMemberPage />
            </PrivateRoute>
          }
        />

        {/* Impact  */}
        <Route
          path="/impacts"
          element={
            <PrivateRoute>
              <CreateImpactsPage />
            </PrivateRoute>
          }
        />

        {/* Team  */}
        <Route
          path="/teams"
          element={
            <PrivateRoute>
              <ListTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/create"
          element={
            <PrivateRoute>
              <CreateTeamPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/teams/edit/:id"
          element={
            <PrivateRoute>
              <EditTeamPage />
            </PrivateRoute>
          }
        />

        {/* Link  */}
        <Route
          path="/links"
          element={
            <PrivateRoute>
              <ListLinkPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/links/create"
          element={
            <PrivateRoute>
              <CreateLinkPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/links/edit/:id"
          element={
            <PrivateRoute>
              <EditLinkPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/other-publications"
          element={
            <PrivateRoute>
              <ListOtherPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/other-publications/create"
          element={
            <PrivateRoute>
              <CreateOtherPublicationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/other-publications/edit/:id"
          element={
            <PrivateRoute>
              <EditOtherPublicationPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
