import { Button, FormHelperText, Grid, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useCreateProjectSponsor } from '../hooks/useProjectSponsorController';

const CreateSponsor = ({
  onSuccess,
  projectId,
}: {
  onSuccess: () => void;
  projectId: number;
}) => {
  const { formik, loading } = useCreateProjectSponsor(projectId, onSuccess);

  return (
    <>
      <Grid item sx={{}}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Name"
                  placeholder=""
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  Image
                </Grid>

                <Box>
                  <input
                    type="file"
                    onChange={({ target: { files } }) => {
                      formik.setFieldValue(
                        'image',
                        files && files.length ? files[0] : null
                      );
                    }}
                    name="image"
                  />
                </Box>
                {formik.touched.image && formik.errors.image && (
                  <FormHelperText error>{formik.errors.image}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                size="small"
                sx={{ mr: 3 }}
              >
                {loading ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </>
  );
};

export default CreateSponsor;
