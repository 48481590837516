import { FormikHelpers, useFormik } from 'formik';
import { TeamEdit, Team } from '../team';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
} from '@mui/material';
import { getMediaUrl } from '../../../core/utils/image';

type TeamProps = {
  onSubmit: (
    value: TeamEdit,
    helpers: FormikHelpers<TeamEdit>
  ) => Promise<void>;
  submitting: boolean;
  team: Team;
};

const EditTeamForm = ({ onSubmit, submitting, team }: TeamProps) => {
  const handleSubmit = async (
    value: TeamEdit,
    helpers: FormikHelpers<TeamEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: TeamEdit = {
    name: team.name,
    role: team.role,
    desc: team.desc,
    email: team.email,
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Name"
                      placeholder=""
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                      helperText={formik.errors.name}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="role"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Role"
                      placeholder=""
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.role)}
                      helperText={formik.errors.role}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="desc"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.desc}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.desc)}
                      helperText={formik.errors.desc}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder=""
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          team.media.length ? team.media[0] : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'image',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="image"
                      />
                    </Box>
                    {formik.touched.image && formik.errors.image && (
                      <FormHelperText error>
                        {formik.errors.image}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditTeamForm;
