import { Box } from "@mui/material";
import { useState } from "react";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Layout from "../../../core/ui/layout/Layout";
import TableContainer from "../../../core/ui/table/TableContainer";
import { TableHeader } from "../../../core/ui/table/tableTypes";
import { Paginated } from "../../../core/utils/types";
import { parseQuery } from "../../../core/utils/utility";
import { Link } from "../link";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";

const tableHeaders: TableHeader[] = [
  { field: 'title', label: 'Title', align: 'left' },
  { field: 'url', label: 'Url', align: 'left' },
  { field: 'category', label: 'Category', align: 'left' },
];
const LinkList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {

    await fetchData(`links${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Link>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-phe24/links/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage) toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };
  
  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 1200 }}>
          <TableContainer
            modelToken="link"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Links"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{ 
              edit: "/links/edit",
              create: "/links/create",
              delete: "/links",
              view: "/links",
             }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
              canViewItem: false
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default LinkList;
