import { FormikHelpers, useFormik } from 'formik';
import { VideoEdit, Video } from '../video';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type VideoProps = {
  onSubmit: (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => Promise<void>;
  submitting: boolean;
  video: Video;
};

const EditVideoForm = ({ onSubmit, submitting, video }: VideoProps) => {
  const handleSubmit = async (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: VideoEdit = {
    title: video.title,
    link: video.link,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="link"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Embed Link"
                      placeholder="https://www.youtube.com/embed/bLNzGxcY658"
                      value={formik.values.link}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.link)}
                      helperText={formik.errors.link}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditVideoForm;
