import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { ImpactsCreate, Impacts } from '../impacts';
import CreateImpactsForm from '../components/CreateImpactsForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import { useState, useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';

const CreateImpactsContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [impacts, setImpacts] = useState<Impacts | null>(null);

  const fetchImpacts = () =>
    fetchData(`impacts`, {
      onSuccess: (data: Impacts) => {
        setImpacts(data);
      },
    });

  useEffect(() => {
    fetchImpacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ImpactsCreate,
    { setFieldError }: FormikHelpers<ImpactsCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'auth-phe24/impacts',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Impacts Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/impacts" />}>
      <Loader loading={loading}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 2, px: 1 }}>
            <Typography variant="h5">
              {impacts ? 'Edit' : 'Add'} Impacts
            </Typography>
          </Grid>
          <CreateImpactsForm
            impacts={impacts}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default CreateImpactsContainer;
