import { Close, UploadFileOutlined } from '@mui/icons-material';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import useFileUpload from '../../../core/hooks/useFileUpload';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const UploadFiles = ({
  onDone,
  publicationId,
}: {
  onDone: () => void;
  publicationId: number;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const { uploadFile, uploading, progress } = useFileUpload();

  const handleUpload = async (): Promise<boolean> => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file[]', file);
    });

    let uploaded = false;

    await uploadFile(
      `auth-phe24/publications/${publicationId}/file`,
      formData,
      {
        onSuccess: () => {
          toastMessage('Files Uploaded');
          uploaded = true;
          setTimeout(() => onDone(), 100);
        },
        onError: (err) => {
          toastError(err);
        },
      }
    );

    return uploaded;
  };

  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => setFiles([...files, ...acceptedFiles])}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            maxWidth="100%"
            sx={{ p: 1, border: '1px solid  lightgrey', borderRadius: '8px' }}
          >
            <div {...getRootProps()} style={{ cursor: 'pointer' }}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
              {uploading && (
                <Grid container justifyContent="flex-start">
                  <Grid item xs={10}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{ my: '5px' }}
                    />
                  </Grid>
                </Grid>
              )}

              {files.map((file, i) => (
                <Grid key={i} display="flex" alignItems="center" mb={1}>
                  <Box flex={1} mr="12px">
                    <Typography>{file.name}</Typography>
                  </Box>

                  <Close
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFiles((prevFiles) => {
                        const curFiles = [...prevFiles];
                        curFiles.splice(i, 1);

                        return curFiles;
                      });
                    }}
                  />
                </Grid>
              ))}

              {Boolean(files.length) && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpload();
                  }}
                  startIcon={<UploadFileOutlined />}
                  disabled={uploading}
                  variant="contained"
                >
                  Upload
                </Button>
              )}
            </div>
          </Box>
        )}
      </Dropzone>
    </>
  );
};

export default UploadFiles;
