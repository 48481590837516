import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { OtherPublicationCreate } from '../otherPublication';
import CreateOtherPublicationForm from '../components/CreateOtherPublicationForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const CreateOtherPublicationContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: OtherPublicationCreate,
    { setFieldError }: FormikHelpers<OtherPublicationCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'auth-phe24/other-publications',
      data: {
        title: values.title,
        link: JSON.stringify([]),
      },
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('OtherPublication Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/other-publications" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add OtherPublication</Typography>
        </Grid>
        <CreateOtherPublicationForm
          onSubmit={handleSubmit}
          submitting={submitting}
        />
      </Grid>
    </Layout>
  );
};

export default CreateOtherPublicationContainer;
