import { FormikHelpers, useFormik } from 'formik';
import { ProjectsEdit, Projects } from '../projects';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
  Typography,
  IconButton,
  Modal,
} from '@mui/material';
import { getMediaUrl } from '../../../core/utils/image';
import EditSponsor from './EditSponsor';
import { AddCircle } from '@mui/icons-material';
import { useState } from 'react';
import CreateSponsor from './CreateSponsor';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
};

type ProjectsProps = {
  onSubmit: (
    value: ProjectsEdit,
    helpers: FormikHelpers<ProjectsEdit>
  ) => Promise<void>;
  submitting: boolean;
  projects: Projects;
  fetchProject: () => void;
};

const EditProjectsForm = ({
  onSubmit,
  submitting,
  projects,
  fetchProject,
}: ProjectsProps) => {
  const [showCreate, setShowCreate] = useState(false);

  const handleSubmit = async (
    value: ProjectsEdit,
    helpers: FormikHelpers<ProjectsEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ProjectsEdit = {
    title: projects.title || '',
    category: projects.category || '',
    description: projects.description || '',
    sitemap: projects.sitemap || '',
    cover: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} sx={{ mr: 3 }}>
            <Grid item>
              <Paper
                sx={{
                  p: 2,
                  pb: 3,
                  maxWidth: '800px',
                  minWidth: '500px',
                  width: '100%',
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="inprogress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="sitemap"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Sitemap"
                      placeholder="https://www.google.com/maps/embed"
                      value={formik.values.sitemap}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.sitemap)}
                      helperText={formik.errors.sitemap}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Cover Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          projects.media.length ? projects.media[0] : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cover',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cover"
                      />
                    </Box>
                    {formik.touched.cover && formik.errors.cover && (
                      <FormHelperText error>
                        {formik.errors.cover}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>

        <Grid container display="flex" flexDirection="column" sx={{ ml: 2 }}>
          <Grid item display="flex" justifyContent="space-between">
            <Typography variant="h5" sx={{ mb: 2 }}>
              Sponsors
            </Typography>

            <IconButton onClick={() => setShowCreate(true)}>
              <AddCircle color="primary" />
            </IconButton>
          </Grid>

          {projects.sponsors.map((sponsor) => (
            <EditSponsor
              key={sponsor.id}
              sponsor={sponsor}
              fetchProject={fetchProject}
            />
          ))}
        </Grid>
      </Box>

      <Modal open={showCreate} onClose={() => setShowCreate(false)}>
        <Box sx={{ ...modalStyle }}>
          <CreateSponsor
            projectId={projects.id}
            onSuccess={() => {
              setShowCreate(false);
              fetchProject();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default EditProjectsForm;
