import {
  BarChart,
  Campaign,
  Group,
  GroupAdd,
  Image,
  Link,
  Newspaper,
  Videocam,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/announcements"
                label="Announcement"
                icon={<Campaign />}
                active={location.pathname.startsWith('/announcements')}
              />
              <SidebarLink
                to="/galleries"
                label="Gallery"
                icon={<Image />}
                active={location.pathname.startsWith('/galleries')}
              />
              <SidebarLink
                to="/links"
                label="Link"
                active={location.pathname.startsWith('/links')}
                icon={<Link />}
              />
              <SidebarLink
                to="/members"
                label="Member"
                icon={<Group />}
                active={location.pathname.startsWith('/members')}
              />
              <SidebarLink
                to="/news"
                label="News"
                icon={<Newspaper />}
                active={location.pathname.startsWith('/news')}
              />
              <SidebarLink
                to="/projects"
                label="Projects"
                active={location.pathname.startsWith('/projects')}
              />

              <SidebarLink
                to="/publications"
                label="Publication"
                active={location.pathname.startsWith('/publications')}
              />
              <SidebarLink
                to="/other-publications"
                label="Other Publication"
                active={location.pathname.startsWith('/other-publications')}
              />
              <SidebarLink
                to="/strategic-plans"
                label="Strategic Plan"
                icon={<BarChart />}
                active={location.pathname.startsWith('/strategic-plans')}
              />
              <SidebarLink
                to="/videos"
                label="Video"
                icon={<Videocam />}
                active={location.pathname.startsWith('/videos')}
              />
              <SidebarLink
                to="/teams"
                label="Team"
                active={location.pathname.startsWith('/teams')}
                icon={<GroupAdd />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>
              <SidebarLink
                to="/impacts"
                label="Impacts"
                active={location.pathname.startsWith('/impacts')}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                Forms
              </Typography>
              <SidebarLink
                to="/contact"
                label="Contact Us"
                active={location.pathname.startsWith('/contact')}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
