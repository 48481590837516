import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import { VideoCreate } from "../video";
import CreateVideoForm from "../components/CreateVideoForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const CreateVideoContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: VideoCreate,
    { setFieldError }: FormikHelpers<VideoCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: 'auth-phe24/videos',
      data: formData,
      headers: {
        'Content-Type': 'application/json'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Video Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/videos" />}>
      
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Video</Typography>
        </Grid>
        <CreateVideoForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
      
    </Layout>
  );
};

export default CreateVideoContainer;
