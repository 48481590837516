import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { LinkEdit, Link } from '../link';
import EditLinkForm from '../components/EditLinkForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';

const EditLinkContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [link, setLink] = useState<Link | null>(null);

  const fetchLink = () =>
    fetchData(`links/${id}`, {
      onSuccess: (data: Link) => {
        setLink(data);
      },
    });

  useEffect(() => {
    fetchLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: LinkEdit,
    { setFieldError }: FormikHelpers<LinkEdit>
  ) => {
    await callApi({
      endpoint: `auth-phe24/links/${id}`,
      method: 'patch',
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchLink();
        toastMessage('Link Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/links" />}>
      <Loader loading={loading || !link}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Link</Typography>
          </Grid>
          <EditLinkForm
            link={link!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditLinkContainer;
