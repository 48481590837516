import { Box, Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { PublicationEdit, Publication } from '../publication';
import EditPublicationForm from '../components/EditPublicationForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import { Media } from '../../../core/utils/types';
import FileListItem from '../../../core/ui/utility/FileListItem';
import UploadFiles from '../components/UploadFiles';

const EditPublicationContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [publication, setPublication] = useState<Publication | null>(null);
  const { callApi: callDeleteApi } = useSendApiData();

  const fetchPublication = () =>
    fetchData(`publications/${id}`, {
      onSuccess: (data: Publication) => {
        setPublication(data);
      },
    });

  useEffect(() => {
    fetchPublication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: PublicationEdit,
    { setFieldError }: FormikHelpers<PublicationEdit>
  ) => {
    const formData: any = parseFormQuery(values, []);

    formData.set('other', values.other ? 1 : 0);

    await callApi({
      endpoint: `auth-phe24/publications/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchPublication();
        toastMessage('Publication Edited');
      },
    });
  };

  const handleFileDelete = async (media: Media) => {
    await callDeleteApi({
      endpoint: `auth-phe24/publications/${publication!.id}/file/${media.id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        toastMessage('File Removed');
        // TODO: Fix Unmount component issue
        setTimeout(() => fetchPublication(), 100);
      },
      onError: () => {
        toastError('Something went wrong while deleting. Try again');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/publications" />}>
      <Loader loading={loading || !publication}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Publication</Typography>
          </Grid>
          <EditPublicationForm
            publication={publication!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />

          <Box sx={{ maxWidth: '650px', mt: 3 }}>
            {publication !== null && (
              <UploadFiles
                onDone={() => fetchPublication()}
                publicationId={publication.id}
              />
            )}

            <Grid container sx={{ mt: 1 }} gap={1}>
              {publication &&
                publication.media.map((m) => (
                  <FileListItem
                    media={m}
                    key={m.id}
                    onDelete={() => handleFileDelete(m)}
                  />
                ))}
            </Grid>
          </Box>
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditPublicationContainer;
