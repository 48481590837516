import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import DeleteAction from '../../../core/ui/utility/DeleteAction';
import { getMediaUrl } from '../../../core/utils/image';
import {
  useDeleteProjectSponsor,
  useEditProjectSponsor,
} from '../hooks/useProjectSponsorController';
import { ProjectSponsor } from '../projects';

const EditSponsor = ({
  sponsor,
  fetchProject,
}: {
  sponsor: ProjectSponsor;
  fetchProject: () => void;
}) => {
  const { formik, loading } = useEditProjectSponsor(sponsor, fetchProject);
  const { deleteSponsor } = useDeleteProjectSponsor(fetchProject);

  return (
    <>
      <Grid item>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Name"
                  placeholder=""
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <Grid item xs={12}>
                  Image
                </Grid>
                <Grid item sx={{ mr: 3 }}>
                  <img
                    src={getMediaUrl(
                      sponsor?.media.length ? sponsor.media[0] : null
                    )}
                    width="256px"
                    alt="cover"
                  />
                </Grid>
                <Box>
                  <input
                    type="file"
                    onChange={({ target: { files } }) => {
                      formik.setFieldValue(
                        'image',
                        files && files.length ? files[0] : null
                      );
                    }}
                    name="image"
                  />
                </Box>
                {formik.touched.image && formik.errors.image && (
                  <FormHelperText error>{formik.errors.image}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
            >
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                size="small"
                sx={{ mr: 3 }}
              >
                {loading ? 'Saving' : 'Save'}
              </Button>

              <DeleteAction
                message="Are you sure you want to remove sponsor?"
                onDelete={async (e) => {
                  await deleteSponsor(sponsor.id);
                  fetchProject();
                }}
              />
            </Grid>
          </Paper>
        </form>
      </Grid>
    </>
  );
};

export default EditSponsor;
