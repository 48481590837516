import { FormikHelpers, useFormik } from 'formik';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError } from '../../../core/utils/ui/alert';
import { parseFormQuery } from '../../../core/utils/utility';
import { parseValidationErrors } from '../../../core/utils/validation';
import {
  ProjectSponsor,
  ProjectSponsorCreate,
  ProjectSponsorEdit,
} from '../projects';

export const useCreateProjectSponsor = (
  projectId: number,
  onSuccess: () => void
) => {
  const { callApi, loading } = useSendApiData();

  const initialValues: ProjectSponsorCreate = {
    project_id: projectId,
    name: '',
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values,
      { setFieldError }: FormikHelpers<ProjectSponsorEdit>
    ) => {
      let success = false;
      const data = parseFormQuery(values);
      await callApi({
        endpoint: `auth-phe24/project-sponsors`,
        data,
        onSuccess: () => {
          success = true;
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });

      if (success) {
        onSuccess();
      }
    },
  });

  return { formik, loading };
};

export const useEditProjectSponsor = (
  sponsor: ProjectSponsor,
  fetchProject: () => void
) => {
  const { callApi, loading } = useSendApiData();

  const initialValues: ProjectSponsorEdit = {
    name: sponsor.name || '',
    image: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values,
      { setFieldError }: FormikHelpers<ProjectSponsorEdit>
    ) => {
      let success = false;
      const data = parseFormQuery(values);
      await callApi({
        endpoint: `auth-phe24/project-sponsors/${sponsor.id}?_method=PATCH`,
        data,
        onSuccess: () => {
          success = true;
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });

      if (success) {
        fetchProject();
      }
    },
  });

  return { formik, loading };
};

export const useDeleteProjectSponsor = (fetchProject: () => void) => {
  const { callApi, loading: deleting } = useSendApiData();

  const deleteSponsor = async (id: number) => {
    let success = false;
    await callApi({
      endpoint: `auth-phe24/project-sponsors/${id}`,
      method: 'delete',
      data: {},
      onSuccess: () => {
        success = true;
      },
    });

    if (success) {
      fetchProject();
    }
  };

  return { deleteSponsor, deleting };
};
