import { FormikHelpers, useFormik } from 'formik';
import { StrategicPlanEdit, StrategicPlan } from '../strategicPlan';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
} from '@mui/material';
import FileContainer from '../../../core/ui/utility/FileContainer';

type StrategicPlanProps = {
  onSubmit: (
    value: StrategicPlanEdit,
    helpers: FormikHelpers<StrategicPlanEdit>
  ) => Promise<void>;
  submitting: boolean;
  strategicPlan: StrategicPlan;
};

const EditStrategicPlanForm = ({
  onSubmit,
  submitting,
  strategicPlan,
}: StrategicPlanProps) => {
  const handleSubmit = async (
    value: StrategicPlanEdit,
    helpers: FormikHelpers<StrategicPlanEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: StrategicPlanEdit = {
    title: strategicPlan.title,
    description: strategicPlan.description,
    file: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3, minWidth: 500 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      multiline
                      rows={4}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      File
                    </Grid>
                    <FileContainer media={strategicPlan.media[0]} />
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'file',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="file"
                      />
                    </Box>
                    {formik.touched.file && formik.errors.file && (
                      <FormHelperText error>
                        {formik.errors.file}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditStrategicPlanForm;
