import { FormikHelpers, useFormik } from 'formik';
import { OtherPublicationEdit, OtherPublication } from '../otherPublication';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Add, Remove } from '@mui/icons-material';

type OtherPublicationProps = {
  onSubmit: (
    value: OtherPublicationEdit,
    helpers: FormikHelpers<OtherPublicationEdit>
  ) => Promise<void>;
  submitting: boolean;
  otherPublication: OtherPublication;
};

const EditOtherPublicationForm = ({
  onSubmit,
  submitting,
  otherPublication,
}: OtherPublicationProps) => {
  const [curLinks, setCurLinks] = useState<{ title: string; link: string }[]>(
    JSON.parse(otherPublication.link) || []
  );
  const [curTitle, setCurTitle] = useState('');
  const [curLink, setCurLink] = useState('');

  const handleSubmit = async (
    value: OtherPublicationEdit,
    helpers: FormikHelpers<OtherPublicationEdit>
  ) => {
    await onSubmit(
      {
        title: value.title,
        link: JSON.stringify(curLinks),
      },
      helpers
    );
  };

  const initialValues: OtherPublicationEdit = {
    link: otherPublication.link,
    title: otherPublication.title,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const addLink = () => {
    setCurLinks([...curLinks, { link: curLink, title: curTitle }]);
    setCurLink('');
    setCurTitle('');
  };

  const update = (i: number, field: 'title' | 'link', value: string) => {
    const prevLinks = [...curLinks];
    prevLinks[i][field] = value;

    setCurLinks(prevLinks);
  };

  const remove = (i: number) => {
    setCurLinks((prevLinks) => {
      const cur = [...prevLinks];
      cur.splice(i, 1);

      return cur;
    });
  };

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item>
                <Paper sx={{ p: 2, pb: 3 }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ mt: 2 }}
                  >
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        name="title"
                        size="small"
                        type="text"
                        variant="outlined"
                        label="Title"
                        placeholder=""
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.title)}
                        helperText={formik.errors.title}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item>
                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Paper sx={{ mt: 2, p: 2 }}>
              <Typography sx={{ mb: 2 }} variant="h5">
                Links
              </Typography>
              {curLinks.map((item, i) => (
                <Grid container key={i} alignItems="center" spacing={1} mt={1}>
                  <Grid item>
                    <TextField
                      label="Title"
                      value={item.title}
                      onChange={(e) => update(i, 'title', e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="link"
                      value={item.link}
                      onChange={(e) => update(i, 'link', e.target.value)}
                      size="small"
                    />
                  </Grid>
                  <IconButton
                    color="warning"
                    onClick={() => {
                      remove(i);
                    }}
                  >
                    <Remove />
                  </IconButton>
                </Grid>
              ))}
              <Grid container alignItems="center" spacing={1} mt={2}>
                <Grid item>
                  <TextField
                    label="Title"
                    value={curTitle}
                    onChange={(e) => setCurTitle(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="link"
                    value={curLink}
                    onChange={(e) => setCurLink(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={!curLink || !curTitle}
                    onClick={() => {
                      addLink();
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditOtherPublicationForm;
