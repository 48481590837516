import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { OtherPublicationCreate } from '../otherPublication';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type OtherPublicationProps = {
  onSubmit: (
    value: OtherPublicationCreate,
    helpers: FormikHelpers<OtherPublicationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateOtherPublicationForm = ({
  onSubmit,
  submitting,
}: OtherPublicationProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: OtherPublicationCreate,
    helpers: FormikHelpers<OtherPublicationCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/other-publications/create', { replace: true });
    }
  };

  const initialValues: OtherPublicationCreate = { link: '', title: '' };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateOtherPublicationForm;
